var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.assistant_profiles.length > 0)?_c('b-table-simple',{staticClass:"table-assistant-profile mt-2",attrs:{"responsive":""}},[_c('b-tbody',[_vm._l((_vm.assistant_profiles),function(assistant_profile,index){return [_c('b-tr',{key:'assistant_header' + assistant_profile.id},[_c('b-th',{staticClass:"text-center",attrs:{"colspan":"10","rowspan":"1"}},[_c('div',[_vm._v(" PERFIL AYUDANTE PARA LA "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofilematter", false, "Asignatura" ).toUpperCase())+" "+_vm._s(index + 1)+" "),(
                  index == 0 &&
                  _vm.allows_crud &&
                  _vm.oldMatter &&
                  !_vm.oldMatter.is_closed
                )?_c('button-add',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                  `Agregar un nuevo Perfil del Ayudante`
                ),expression:"\n                  `Agregar un nuevo Perfil del Ayudante`\n                ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"ml-1",on:{"click":function($event){return _vm.createAssistantProfileBase()}}}):_vm._e(),(_vm.allows_crud && _vm.oldMatter && !_vm.oldMatter.is_closed)?_c('button-delete',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                  `Eliminar el Perfil del Ayudante`
                ),expression:"\n                  `Eliminar el Perfil del Ayudante`\n                ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"ml-1",on:{"click":function($event){return _vm.deleteAssistantProfile(assistant_profile)}}}):_vm._e()],1)])],1),_c('b-tr',{key:'assistant_profile' + assistant_profile.id},[_c('b-td',{staticStyle:{"width":"25%"},attrs:{"colspan":"1"}},[_c('strong',[_vm._v("Perfil Ayudante:")])]),_c('b-td',{attrs:{"colspan":"9"}},[_c('div',{staticClass:"d-flex"},[[_c('div',{staticClass:"wrapper2 rich-text-content w-100",domProps:{"innerHTML":_vm._s(assistant_profile.profile)}}),(_vm.allows_crud && _vm.oldMatter && !_vm.oldMatter.is_closed)?[_c('button-edit',{attrs:{"tabindex":"-1"},on:{"click":function($event){return _vm.$bvModal.show(
                        `edit-assistant_profile-modal-${assistant_profile.id}-${_vm.matter_form_view}`
                      )}}}),_c('b-modal',{attrs:{"id":`edit-assistant_profile-modal-${assistant_profile.id}-${_vm.matter_form_view}`,"title":"Editar Perfil Ayudante:","size":"lg","hide-footer":"","no-enforce-focus":""}},[_c('NewRichTextEditor',{attrs:{"Object":assistant_profile,"Text":assistant_profile.profile,"permit_blank":true},on:{"save":_vm.updateAssistantProfileChild,"close":function($event){return _vm.$bvModal.hide(
                          `edit-assistant_profile-modal-${assistant_profile.id}-${_vm.matter_form_view}`
                        )}}})],1)]:_vm._e()]],2)])],1),_c('b-tr',{key:'assistant_role' + assistant_profile.id},[_c('b-td',{staticStyle:{"width":"25%"},attrs:{"colspan":"1"}},[_c('strong',[_vm._v("Rol del Ayudante:")])]),_c('b-td',{attrs:{"colspan":"9"}},[_c('div',{staticClass:"d-flex"},[[_c('div',{staticClass:"wrapper2 rich-text-content w-100",domProps:{"innerHTML":_vm._s(assistant_profile.role)}}),(_vm.allows_crud && _vm.oldMatter && !_vm.oldMatter.is_closed)?[_c('button-edit',{attrs:{"tabindex":"-1"},on:{"click":function($event){return _vm.$bvModal.show(
                        `edit-assistant_role-modal-${assistant_profile.id}-${_vm.matter_form_view}`
                      )}}}),_c('b-modal',{attrs:{"id":`edit-assistant_role-modal-${assistant_profile.id}-${_vm.matter_form_view}`,"title":"Editar Perfil Ayudante:","size":"lg","hide-footer":"","no-enforce-focus":""}},[_c('NewRichTextEditor',{attrs:{"Object":assistant_profile,"Text":assistant_profile.role,"permit_blank":true},on:{"save":_vm.updateAssistantRole,"close":function($event){return _vm.$bvModal.hide(
                          `edit-assistant_role-modal-${assistant_profile.id}-${_vm.matter_form_view}`
                        )}}})],1)]:_vm._e()]],2)])],1)]})],2)],1):_vm._e(),(_vm.assistant_profiles.length == 0 && _vm.allows_crud && _vm.fetch_finish)?_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"ml-auto mr-auto mb-5",attrs:{"variant":"secondary","size":"sm"},on:{"click":_vm.createAssistantProfileBase}},[_vm._v(" + Agregar Perfil Ayudante ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }